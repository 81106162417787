<template>
	<div>DeviceMoudleList</div>
</template>
<script>
	export default {
		data() {
			return {
				router: false
			}
		},
		methods: {}
	};
</script>

<style lang="less" scoped>

</style>
